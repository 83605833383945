 <template>
    <div class="row">
        <DealModal ref="modal"/>
        <template v-for="(item, $index) in list">
        <div
            class="product-item item col-xs-6 col-sm-6 col-md-4 col-lg-3"
            v-if="item.adpos != 1"
            :key="$index"
            :data-num="$index + 1">
            <div class="panel panel-default panel-deal-item" :data-deal-id="item.id">
                <div class="panel-body">
                    <template v-if="item.end_date">
                        <div v-if="new Date(item.end_date) < new Date()" class="span6 text-left deal-expired">
                            <div class="deal-expired-flag">
                                <i class="fa fa-bell" aria-hidden="true"></i> Expired
                            </div>
                        </div>
                    </template>
                    <div v-else-if="item.label_id" class="ribbon" v-bind:class="['ribbon-badge-' + item.label_id]">
                        <a target="_blank" :href="item.label_link" v-text="item.label_name">
                        </a>
                        <div class="shad">
                        </div>
                    </div>
                    <div class="span6 pull-right">
                        <div class="deal-view-favourite">
                            <a href="#" class="deal-favourite" :data-deal-id="item.id">
                                <span class="fa fa-heart" aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>             
                    <div class="deal-image">
                        <template v-if="item.image_small">
                        <a @click.prevent="openModal(item)" class="deal-page-link" target="_blank" :href="item.link">
                            <img v-bind:src="item.image_small" v-bind:title="item.title" />
                        </a>
                        </template>
                        <template v-else>
                            <template v-if="item.offer_label">
                                <span class="deal-offer-label">
                                    <a @click.prevent="openModal(item)" class="deal-page-link" target="_blank" :href="item.link">{{item.offer_label}}</a>
                                </span>
                            </template>
                            <template v-else>
                                <a @click.prevent="openModal(item)" class="deal-page-link" target="_blank" :href="item.link">
                                    <img v-bind:src="item.store_image" v-bind:title="item.title" />
                                </a>
                            </template>
                        </template>
                    </div>
                    <div class="span6 pull-left">
                        <div class="btn-group">
                        <template v-if="item.coupon_code">
                            <a target="_blank" :href="item.getdeal" class="deal-link btn btn-success btn-sm" rel="nofollow">
                                Get coupon <span class="fa fa-cut"></span>
                            </a>
                        </template>
                        <template v-else>
                            <a target="_blank" :href="item.getdeal" :data-title="item.title" :data-store="item.store_name" class="getdeal-link btn btn-success btn-sm js-get-deal-link" rel="nofollow">
                                Get deal <span class="fa fa-shopping-cart"></span>
                            </a>
                        </template>
                        </div>
                    </div>
                    <div class="span6 text-right top-store-link">
                        <a target="_blank" :href="item.store_link" class="store-link">
                            <img v-bind:src="item.store_icon" v-bind:title="item.store_name" width=32 />
                        </a>
                    </div>                                   
                    <div class="span6 pull-left top-category-link">
                        <small class="text-muted"><i class="fa fa-tags" aria-hidden="true"></i>&nbsp;<a target="_blank" :href="item.sub_category_link" v-text="item.sub_category_name"></a></small>
                    </div>
                    <div class="span6 text-right top-more-link">
                        <small class="text-muted"><i class="fa fa-external-link-alt" aria-hidden="true"></i>&nbsp;<a target="_blank" :href="item.link">More Info</a></small>
                    </div>
                </div>
                <div class="panel-footer clearfix">
                    <div class="row-fluid deal-title">
                        <a @click.prevent="openModal(item)" class="deal-page-link" target="_blank" :href="item.link" v-text="item.title"></a>
                    </div>
                    <div class="row-fluid deal-price-wrapper">
                        <template v-if="item.actual_price > 0 && item.discount_price > 0">
                        <div class="span6 pull-left deal-price">
                            <template v-if="item.actual_price != item.discount_price">
                            <small class="text-muted">
                                <del v-html="asPriceFormat(item.actual_price)"></del>
                            </small>
                            </template>
                            <span class="deal-price-discount">
                                <strong v-html="asPriceFormat(item.discount_price)"></strong>
                            </span>
                        </div>
                        <div class="span6 text-right deal-discount" v-if="getPercentageValue(item.actual_price, item.discount_price) > 0">
                            <span class="deal-discount-circular">{{asPercentage(item.actual_price, item.discount_price)}}</span>
                        </div>
                        </template>
                        <template v-else-if="item.coupon_code">
                        <div class="input-group">
                        <span class="input-group-addon">Coupon Code</span>
                        <div class="form-control">
                            <div class="copy-coupon">
                                <a target="_blank" :href="item.getdeal" class="deal-link btn btn-sm get-coupon-code" rel="nofollow">
                                    Get coupon <span class="fa fa-cut"></span>
                                </a>
                            </div>
                        </div>
                        </div>
                        </template>
                        <template v-else-if="item.actual_price > 0">
                        <div class="span6 pull-left deal-price">
                            <strong v-text="item.actual_price"></strong>
                        </div>
                        </template>
                        <template v-else-if="item.discount_price > 0">
                        <div class="span6 pull-left deal-price">
                            <strong v-text="item.discount_price"></strong>
                        </div>
                        </template>
                    </div>
                    <div class="row-fluid deal-share-wrapper">
                        <div class="span6 pull-left deal-time">
                            <small class="text-muted">
                                <a class="deal-page-link" target="_blank" :href="item.link">
                                    <i class='glyphicon glyphicon-time deal-time-icon'></i>
                                    <span class='deal-time-value'>
                                        <time class="timeago" :datetime="item.created"></time>
                                    </span>
                                </a>
                            </small>
                        </div>
                    </div>                    
                </div>               
            </div>
        </div>
        <div v-if="item.adpos == 1" class="product-item item col-xs-6 col-sm-6 col-md-4 col-lg-3" :key="$index"
            :data-num="$index + 1">
            <div class="panel panel-default panel-deal-item">
                <ins class="adsbygoogle"
                     style="display:block; text-align:center;"
                     data-ad-format="fluid"
                     data-ad-layout="image-top"
                     data-ad-layout-key="-8g+12-an+d3+bo"
                     data-ad-client="ca-pub-5363892179843638"
                     data-ad-slot="7250902701"></ins>
            </div>
        </div>
        </template>
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>

    </div>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import DealModal from './DealModal';
import {Deals} from '../Mixins/Deals.js';

export default {
  mixins: [Deals],
  components: {
    InfiniteLoading,
    DealModal
  },    
  name: 'DealList',
  data: function() {
    return {
      deal_api: window.deal_api,
      list: [],
      adpos: 6,
      showModal: false
    }
  },
  updated: function() {
    document.dispatchEvent(new CustomEvent("updated:deal"));
  },
  methods: {
    infiniteHandler($state) {
      axios.get(this.deal_api, {
        params: {
          //page: this.page,
        },
      }).then(({ data }) => {
        if (data.items.length) {
          this.deal_api = data._links.next.href.replace('http://','//');
          data.items.splice(Math.ceil(Math.random() * 7), 0 , {'adpos':1});
          this.list.push(...data.items);
          $state.loaded();
          document.dispatchEvent(new CustomEvent("updated:deal"));
        } else {
          $state.complete();
        }
      });
    },
    openModal(item) {
        this.$refs.modal.openModal(item);
    }
  }

}
</script>