export const Deals = {
    methods: {
        getPercentageValue: function(actual_price, discount_price) {
            return Math.round(((actual_price-discount_price)/actual_price)*100);
        },
        asPercentage: function(actual_price, discount_price) {
        var percentage = Math.round(((actual_price-discount_price)/actual_price)*100);
            if(percentage > 0){
                return percentage+'% off';
            }
        },
        asPriceFormat: function(val) {
            return window.currency_symbol+parseFloat(val).toFixed(window.currency_decimal);
        }
    }
};