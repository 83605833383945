 <template>
    <div class="row">
        <template v-for="(item, $index) in list">
        <div
            class="product-item item col-xs-6 col-sm-6 col-md-4 col-lg-3"
            v-if="item.adpos != 1"
            :key="$index"
            :data-num="$index + 1">
            <div class="panel panel-default panel-deal-item">
                <div class="panel-body">
                    <div class="deal-image">
                        <a target="_blank" :href="item.link">
                            <img v-bind:src="item.image_small" v-bind:title="item.title" />
                        </a>
                    </div>
                    <div class="span6 pull-left top-category-link">
                        <small class="text-muted"><i class="fa fa-tags" aria-hidden="true"></i>&nbsp;<a target="_blank" :href="item.sub_category_link" v-text="item.sub_category_name"></a></small>
                    </div>
                    <div class="span6 text-right top-brand-link">
                        <small class="text-muted"><i class="fas fa-store-alt"></i>&nbsp;<a target="_blank" :href="item.brand_link" v-text="item.brand_name"></a></small>
                    </div>
                </div>
                <div class="panel-footer clearfix">
                    <div class="row-fluid deal-title text-center">
                        <a target="_blank" :href="item.link" v-text="item.title"></a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="item.adpos == 1" class="product-item item col-xs-6 col-sm-6 col-md-4 col-lg-3" :key="$index"
            :data-num="$index + 1">
            <div class="panel panel-default panel-deal-item">
                <ins class="adsbygoogle"
                     style="display:block; text-align:center;"
                     data-ad-format="fluid"
                     data-ad-layout="image-top"
                     data-ad-layout-key="-8g+12-an+d3+bo"
                     data-ad-client="ca-pub-5363892179843638"
                     data-ad-slot="7250902701"></ins>
            </div>
        </div>
        </template>
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>

    </div>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    InfiniteLoading,
  },    
  name: 'ProductList',
  data: function() {
    return {
      product_api: window.product_api,
      list: [],
      adpos: 6,
    }
  },
  updated: function() {
    document.dispatchEvent(new CustomEvent("updated:product"));
  },
  methods: {
    infiniteHandler($state) {
      axios.get(this.product_api, {
        params: {
          //page: this.page,
        },
      }).then(({ data }) => {
        if (data.items.length) {
          this.product_api = data._links.next.href.replace('http://','//');
          data.items.splice(Math.ceil(Math.random() * 7), 0 , {'adpos':1});
          this.list.push(...data.items);
          $state.loaded();
          document.dispatchEvent(new CustomEvent("updated:product"));
        } else {
          $state.complete();
        }
      });
    },
  }

}
</script>