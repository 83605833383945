import Vue from 'vue'
//import App from './App.vue'
import ProductList from './components/ProductList.vue'
import DealList from './components/Deal/DealList.vue'

Vue.config.productionTip = false

Vue.use(require('vue-moment'));

if(document.getElementById("product-list")){
  new Vue({
    render: h => h(ProductList),
  }).$mount('#product-list')
}

if(document.getElementById("deal-list")){
  new Vue({
    render: h => h(DealList),
  }).$mount('#deal-list')
}