<template>
  <div>
    <transition name="modal">
      <div class="modal fade" id="deal-modal" tabindex="-1" role="dialog" aria-labelledby="deal-modal" :data-deal-id="item.id">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title"><a class="deal-page-link" target="_blank" :href="item.link" v-text="item.title"></a></h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4 col-sm-4">
                    <template v-if="item.end_date">
                        <div v-if="new Date(item.end_date) < new Date()" class="span6 text-left deal-expired">
                            <div class="deal-expired-flag">
                                <i class="fa fa-bell" aria-hidden="true"></i> Expired
                            </div>
                        </div>
                    </template>
                    <div v-else-if="item.label_id" class="ribbon" v-bind:class="['ribbon-badge-' + item.label_id]">
                        <a target="_blank" :href="item.label_link" v-text="item.label_name">
                        </a>
                        <div class="shad">
                        </div>
                    </div>
                    <div class="span6 pull-right">
                        <div class="deal-view-favourite">
                            <a href="#" class="deal-favourite" :data-deal-id="item.id">
                                <span class="fa fa-heart" aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>             
                    <div class="deal-view-image thumbnail">
                        <template v-if="item.image_small">
                        <a class="deal-link-new" target="_blank" :href="item.link">
                            <img v-bind:src="item.image_small" v-bind:title="item.title" />
                        </a>
                        </template>
                        <template v-else>
                            <template v-if="item.offer_label">
                                <span class="deal-offer-label">
                                    <a class="deal-link" target="_blank" :href="item.link">{{item.offer_label}}</a>
                                </span>
                            </template>
                        </template>
                        <a target="_blank" :href="item.store_link" class="store-link">
                          <img v-bind:src="item.store_image" v-bind:title="item.store_name"/>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8">
                        <div class="row-fluid clearfix">
                            <ul class="list-inline">
                                <li class="deal-view-time">
                                    <small class="text-muted"><i class="glyphicon glyphicon-time deal-time-icon"></i><time class="timeago" :datetime="item.created"></time></small>
                                </li>
                                <li>
                                    <small class="text-muted"><i class="fa fa-tags" aria-hidden="true"></i>&nbsp;<a target="_blank" :href="item.sub_category_link" v-text="item.sub_category_name"></a></small>
                                </li>
                                <li>
                                    <small class="text-muted"><i class="fa fa-comment" aria-hidden="true"></i>&nbsp;<a :href="item.link+'#disqus_thread'" :data-disqus-identifier="'deal_'+item.id" target="_blank">Comments</a></small>
                                </li>
                            </ul>
                        </div>
                        <div class="row-fluid deal-view-price clearfix">
                          <template v-if="item.actual_price > 0 && item.discount_price > 0">
                          <div class="span6 pull-left deal-price">
                              <template v-if="item.actual_price != item.discount_price">
                              <small class="text-muted">
                                  <del v-html="asPriceFormat(item.actual_price)"></del>
                              </small>
                              </template>
                              <span class="deal-price-discount">
                                <strong v-html="asPriceFormat(item.discount_price)"></strong>
                              </span>
                          </div>
                          <div class="span6 text-right deal-discount" v-if="getPercentageValue(item.actual_price, item.discount_price) > 0">
                              <span class="deal-discount-circular">{{asPercentage(item.actual_price, item.discount_price)}}</span> 
                          </div>
                          </template>
                          <template v-else-if="item.actual_price > 0">
                          <div class="span6 pull-left deal-price">
                              <strong v-text="item.actual_price"></strong>
                          </div>
                          </template>
                          <template v-else-if="item.discount_price > 0">
                          <div class="span6 pull-left deal-price">
                              <strong v-text="item.discount_price"></strong>
                          </div>
                          </template>
                      </div>
                      <div class="row-fluid deal-view-coupon clearfix">
                        <div class="input-group">
                          <span class="input-group-addon">Coupon Code</span>
                          <div class="form-control">
                            <div class="copy-coupon" id="coupon-code" v-text="item.coupon_code ? item.coupon_code : 'Not required' "></div>
                          </div>
                          <template v-if="item.coupon_code">
                          <span class="input-group-addon clipboard" data-clipboard-target="#coupon-code">copy</span>
                          </template>
                        </div>
                      </div>
                      <template v-if="new Date(item.end_date) > new Date() && item.end_date && item.coupon_code">
                      <div class="row-fluid clearfix coupon_end_date input-group-addon panel">
                          Valid Till - {{item.end_date | moment("DD MMM YYYY")}}
                      </div>
                      </template>
                      <div class="row-fluid deal-view-getdeal clearfix">
                        <a target="_blank" :href="item.getdeal" :data-title="item.title" :data-store="item.store_name" class="deal-view-getdeal-button btn btn-success btn-lg btn-block js-get-deal-link" rel="nofollow">
                          Get deal <span class="fa fa-shopping-cart"></span>
                        </a>
                      </div>
                      <div v-if="item.id" class="row-fluid deal-model-ads clearfix">
                        <ins class="adsbygoogle"
                        style="display:inline-block;width:320px;height:100px"
                        data-ad-client="ca-pub-5363892179843638"
                        data-ad-slot="3276531504"></ins>
                      </div>
                      <div class="row-fluid deal-view-body clearfix ">
                        <div class="body-inner" v-html="item.body"></div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {Deals} from '../Mixins/Deals.js';

export default {
  mixins: [Deals],
  name: 'DealModal',  
  data: function() {
    return {
        item: [],
        showModal: false
    }
  },
  updated: function() {
    document.dispatchEvent(new CustomEvent("updated:dealmodal"));
  },   
  methods: {
      openModal(item) {
          this.item = [];
          this.showModal = true;
          this.item = item;
      },
      closeModal() {
          this.showModal = false;
          this.item = [];
      }           
  }
};
</script>